import React from "react";
import { GSA, User, GSAAssociation } from "./models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import Select from "../common/Select";
import Icon from "@mdi/react";
import { mdiUndo } from "@mdi/js";

const getGSA = GSA.selectByUrlId(gsa => ({
  _associations: gsa.gsaassociations
    .all()
    .toModelArray()
    .map(a => {
      return {
        agency: a.user.agency_label,
        name: a.user.first_name + " " + a.user.last_name,
        ...a.ref
      };
    }),
  ...gsa.ref
}));
const getUsers = User.selectAll("first_name");

const styles = theme => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee"
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)"
    }
  },
  centerAlign: {
    textAlign: "center"
  }
});

const columnData = [
  { id: "name", numeric: false, label: "Name", allowSort: true },
  { id: "agency", numeric: false, label: "Agency", allowSort: true },
  { id: "actions", numeric: false, label: "", allowSort: false }
];

class AssociationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: null,
      page: 0,
      rowsPerPage: 10,
      showDelete: false,
      deleteId: null,
      deleteName: null
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    order === "desc"
      ? this.props.associations.sort((a, b) =>
          b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
        )
      : this.props.associations.sort((a, b) =>
          a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
        );

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  render() {
    const { classes, associations, ormGSAAssociationDelete } = this.props;
    const {
      order,
      orderBy,
      page,
      rowsPerPage,
      showDelete,
      deleteName,
      deleteId
    } = this.state;

    return (
      <Table className={classes.table}>
        <EnhancedTableHead
          columnData={columnData}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        <TableBody>
          {associations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.agency}</TableCell>
                <TableCell>
                  <Tooltip title="Delete Association">
                    <Button
                      className={classes.deleteWidth}
                      onClick={() =>
                        this.setState({
                          deleteName: item.name,
                          deleteId: item.id,
                          showDelete: true
                        })
                      }
                    >
                      <DeleteIcon style={{ color: "#b20000" }} />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          {associations.length < 1 && (
            <TableRow>
              <TableCell colSpan={3} className={classes.centerAlign}>
                No Associations Defined
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {associations.length > 10 && (
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={associations.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        )}
        <WarningDialog
          confirmAction={() => {
            ormGSAAssociationDelete(deleteId);
            this.setState({ showDelete: false });
          }}
          cancelAction={() => this.setState({ showDelete: false })}
          open={showDelete}
          title="Delete Association"
          confirmText="Delete Association"
          text={
            "Are you sure you wish to delete the association of " +
            deleteName +
            "?"
          }
        />
      </Table>
    );
  }
}

class EditGSA extends React.Component {
  constructor(props) {
    super(props);

    this.is_new = false;
    if (props.match.params["id"] === "new") this.is_new = true;
  }

  MAKE_OPTIONS = table => {
    return table.map(row => ({
      label: row.first_name + " " + row.last_name,
      value: row.id
    }));
  };

  errorValidator = values => {
    const validateName = name => {
      return !name ? "Field is required" : null;
    };
    var valObj = {};
    valObj["name"] = validateName(values.name);
    return valObj;
  };

  submitForm = values => {
    const { gsa, ormGSACreate, ormGSAUpdate, match, history } = this.props;
    if (match.params["id"] === "new") {
      ormGSACreate(values);
    } else {
      ormGSAUpdate({
        id: gsa.id,
        ...values
      });
    }
    history.push("/admingsas");
  };

  render() {
    const {
      gsa,
      classes,
      history,
      match,
      users,
      ormGSAAssociationDelete,
      ormGSAAssociationCreate
    } = this.props;
    if (gsa.name === "Not Found")
      // name = not found is from rest framework
      gsa.name = "";

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage GSAs"
              level2link="/admingsas"
              level3={(this.is_new ? "Add" : "Edit") + " GSA"}
              level3link={"/admingsas/" + match.params["id"]}
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/admingsas")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Manage GSAs
            </Button>
          </Grid>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={gsa}
            validateError={this.errorValidator}
            onSubmit={this.submitForm}
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField field="name" label="Name*" fullWidth />
                      <TextField
                        field="abbreviation"
                        label="Abbreviation"
                        fullWidth
                      />
                    </Grid>
                    {!this.is_new && (
                      <Grid item xs={10} sm={6} md={4} lg={3}>
                        <Select
                          eventHandle={val => {
                            ormGSAAssociationCreate({ user: val, gsa: gsa.id });
                            formApi.setValue("_association", null);
                          }}
                          field="_association"
                          label="Add User Association"
                          options={this.MAKE_OPTIONS(users)}
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!this.is_new && (
                    <Grid container spacing={40}>
                      <Grid item xs={12} md={8} lg={6}>
                        <AssociationTable
                          classes={classes}
                          ormGSAAssociationDelete={ormGSAAssociationDelete}
                          associations={gsa._associations}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}

EditGSA = connect(
  (state, ownProps) => ({
    gsa: getGSA(state, ownProps),
    users: getUsers(state, ownProps),
    authState: state.auth
  }),
  {
    ...GSA.actions,
    ...GSAAssociation.actions
  }
)(EditGSA);

export default withStyles(styles)(withRouter(EditGSA));
