import React from "react";
import { GSA, GSP, Agency } from "./models";
import { WaterMeter } from "../wells/models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import Select from "../common/Select";
import TextField from "../common/TextField";
import Icon from "@mdi/react";
import { mdiUndo, mdiAlphaP } from "@mdi/js";
import { createSelector } from "../common/orm";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactMail from "@material-ui/icons/ContactMail";
import Typography from "@material-ui/core/Typography";
import RoomIcon from "@material-ui/icons/Room";
import EditMap from "../common/map/EditMap";
import HelpLabel from "../common/HelpLabel";

const getSite = WaterMeter.selectByUrlId();

const getUserData = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : {}),
    (session, id) => {
        let user = session.User.filter(u => u.id === id).toRefArray()[0];

        return user;
    }
);

const styles = theme => ({
    breadCrumb: {
        width: "100vw",
        margin: "0 -20px",
        padding: "0px 35px !important",
        maxWidth: "105%",
        flexBasis: "auto",
        backgroundColor: "#eeeeee",
    },
    helpText: {
        color: "#666666 !important",
        fontSize: "16px",
        fontWeight: "300",
        fontFamily: "'Source Sans Pro', sans-serif",
        lineHeight: "1",
        margin: "0px",
        marginBottom: "-8px",
    },
});

class EditWaterMeters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quarter_section_choices: null,
            callOptions: false,
            lat: null,
            lng: null,
            zoom: null,
            helpOpen: false,
        };

        this.is_new = false;
        if (props.match.params["id"] === "new") this.is_new = true;

        this.map = React.createRef();
    }

    componentDidMount() {
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Administrator") {
            history.push("/dashboard");
        }
    }

    MAP_OPTIONS = table => {
        return table.map(row => ({
            label: row.display_name,
            value: row.value,
        }));
    };

    MAKE_OPTIONS = table => {
        return table.map(row => ({
            label: row.name,
            value: row.id,
        }));
    };

    setLatLngState(geometry) {
        if (geometry) {
            this.setState({
                lat: geometry.coordinates[1],
                lng: geometry.coordinates[0],
                zoom: 12,
            });
        }
    }

    updateLatLng(e) {
        const { site } = this.props;

        var marker = e.target;
        var loc = marker.getLatLng();
        var map = marker._map;

        var zoom = map.getZoom();
        site.geometry = {
            type: "Point",
            coordinates: [loc.lng, loc.lat],
        };

        this.setState({
            lat: loc.lat,
            lng: loc.lng,
            zoom: zoom,
        });
    }

    errorValidator = values => {
        const validateName = name => {
            return !name ? "Field is required" : null;
        };
        const validateFloat = val => {
            if (parseFloat(val)) {
                return null;
            } else {
                return "Value is not valid";
            }
        };

        var valObj = {};
        valObj["account_number"] = validateName(values.account_number);
        valObj["address"] = validateName(values.address);
        valObj["lot"] = validateName(values.lot);

        return valObj;
    };

    handleChange = evt => {
        this.setState({
            [evt.target.name]: evt.target.value,
        });
    };

    // handleChangeNumeric = (evt) => {
    //   const { site } = this.props;

    //   const re = /^-?\d*\.?\d*$/;
    //   if (evt.target.value === "" || re.test(evt.target.value)) {
    //     this.setState({
    //       [evt.target.name]: evt.target.value,
    //     });

    //     //state doent pick up changes fast enough
    //     let dlat = parseFloat(this.state.lat);
    //     let dlng = parseFloat(this.state.lng);

    //     if (evt.target.name === "lat") {
    //       dlat = parseFloat(evt.target.value);
    //     } else if (evt.target.name === "lng") {
    //       dlng = parseFloat(evt.target.value);
    //     }

    //     if (dlat && dlng) {
    //       site.geometry = {
    //         type: "Point",
    //         coordinates: [dlng, dlat],
    //       };
    //       this.map.current.geometry = site.geometry;
    //     }
    //   }
    // };

    submitForm = values => {
        const { site, ormWaterMeterCreate, ormWaterMeterUpdate, match, history, user } = this.props;

        values.updateby_id = user ? user.id : null;

        // if (this.state.lat && this.state.lng) {
        //   values.lat = this.state.lat;
        //   values.lng = this.state.lng;
        //   values["geometry"] = { coordinates: [this.state.lng, this.state.lat] };
        // }

        values.geometry = site.geometry;
        if (values.ground_surface_elev) {
            values.ground_surface_elev = parseFloat(values.ground_surface_elev);
        }

        if (match.params["id"] === "new") {
            // values.geometry = site.geometry;
            ormWaterMeterCreate(values);
        } else {
            ormWaterMeterUpdate({
                id: site.id,
                ...values,
            });
        }
        history.push("/adminwatermeters");
    };

    getDefaultValues = site => {
        console.log(this.props.site);
        console.log(this.props.agency);
        console.log();
    };

    render() {
        const { site, classes, history, match, gsas, gsp, optionsReq, agency } = this.props;

        const { lat, lng, helpOpen } = this.state;

        if (site && site.geometry && !lat && !lng) {
            this.setLatLngState(site.geometry);
        } else if (site && site.name !== "Not Found" && !site.geometry) {
            this.setLatLngState(site.geometry);
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={24}>
                    <Grid item xs={12} className={classes.breadCrumb}>
                        <BreadcrumbNav
                            level1="Administration"
                            level1link="/administration"
                            level2="Manage Water Meters"
                            level2link="/adminwatermeters"
                            level3={(this.is_new ? "Add" : "Edit") + " Water Meter Site"}
                            level3link={"/adminwatermeters/" + match.params["id"]}
                        />
                    </Grid>
                    <Grid item xs={12} mg={9} lg={6} xl={3}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => history.push("/adminwatermeters")}>
                            <Icon path={mdiUndo} size={1} color="white"></Icon>
                            &nbsp;&nbsp;&nbsp; Return to Water Meter Sites
                        </Button>
                    </Grid>

                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        defaultValues={site}
                        validateError={this.errorValidator}
                        onChange={this.onChange}
                        onSubmit={this.submitForm}>
                        {formApi => (
                            <Grid item xs={12}>
                                <form onSubmit={formApi.submitForm}>
                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <ContactMail style={{ marginTop: 5 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Identification
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        field="account_number"
                                                        label="Account Number"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField field="address" label="Address" fullWidth />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField field="lot" label="Lot" fullWidth />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <ExpansionPanel defaultExpanded>
                                        <ExpansionPanelSummary
                                            classes={{ root: classes.expandedMargin }}
                                            expandIcon={<ExpandMoreIcon />}>
                                            <RoomIcon style={{ marginTop: 5 }} />
                                            <Typography style={{ paddingLeft: 32 }} variant="h6">
                                                Location and Elevation
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={16} className={classes.borderContainer}>
                                                <div style={{ height: "300px", width: "100%" }}>
                                                    <EditMap
                                                        ref={this.map}
                                                        height={"300px"}
                                                        width={"100%"}
                                                        data={site}
                                                        handler={this.updateLatLng.bind(this)}
                                                        geometry={site.geometry}
                                                        zoom={this.state.zoom}
                                                    />
                                                </div>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        value={this.state.lat ? this.state.lat : undefined}
                                                        name="lat"
                                                        field="geometry.coordinates[1]"
                                                        label="Latitude"
                                                        onChange={this.handleChangeNumeric}
                                                        placeholder="Enter Latitude or move map marker"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid className={classes.expansionBorder} item xs={12} md={6} xl={4}>
                                                    <TextField
                                                        value={this.state.lng ? this.state.lng : undefined}
                                                        name="lng"
                                                        field="geometry.coordinates[0]"
                                                        label="Longitude"
                                                        onChange={this.handleChangeNumeric}
                                                        placeholder="Enter Longitude or move map marker"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <Grid container spacing={40} alignItems="center" justify="center">
                                        <Grid item xs={8}>
                                            <Button variant="contained" type="submit" fullWidth color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        )}
                    </Form>
                </Grid>
            </AppContainer>
        );
    }
}

EditWaterMeters = connect(
    (state, ownProps) => ({
        site: getSite(state, ownProps),
        user: getUserData(state, ownProps),
        authState: state.auth,
    }),
    {
        ...WaterMeter.actions,
    }
)(EditWaterMeters);

export default withStyles(styles)(withRouter(EditWaterMeters));
