import React from "react";
import {
  Map,
  Marker,
  TileLayer,
  LayersControl,
  WMSTileLayer
} from "react-leaflet";

import MapLayers from "./MapLayers";
//import esri from 'esri-leaflet';

const { Overlay } = LayersControl;

class CreateMap extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 36.642,
      lng: -119.817,
      zoom: 13
    };
  }
  handleZoom(lat, lng) {
    this.setState({ lat: lat });
    this.setState({ lng: lng });
    //render(); // Probably the wrong way to handle it.
  }
  markerLayers() {
    // Get all markers from /scripts/autocomplete.php
  }

  toggleDraggable = () => {
    console.log("we are dragging");
    this.setState({ draggable: !this.state.draggable });
  };

  // style={{ position: "absolute", top:"40px", left:"10px" }}
  render() {
    const position = [this.state.lat, this.state.lng];
    debugger;
    return (
      <Map center={position} zoom={this.state.zoom} maxZoom="20">
        <Marker
          draggable={this.state.draggable}
          onDragend={this.props.updateLatLng}
          position={position}
        ></Marker>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Esri Topographic">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri Streets">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri Imagery">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <Overlay name="Groundwater Wells">
            <MapLayers />
          </Overlay>
          <LayersControl.Overlay name="Kings Subbasin Boundary">
            <WMSTileLayer
              layers="kings_subbasin"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="GroundwaterService Agencies">
            <WMSTileLayer
              layers="gsa"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="E Clay Eastern Extent">
            <WMSTileLayer
              layers="clay"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Depth to Base of Unconfined Groundwater">
            <WMSTileLayer
              layers="depth_base"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Specific Yield Units">
            <WMSTileLayer
              layers="yield_units"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Land Use DWR">
            <WMSTileLayer
              layers="landuse"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="Water Level Contours - Spring 1963">
            <WMSTileLayer
              layers="spring_1963"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1964">
            <WMSTileLayer
              layers="spring_1964"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1965">
            <WMSTileLayer
              layers="spring_1965"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1996">
            <WMSTileLayer
              layers="spring_1996"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1997">
            <WMSTileLayer
              layers="spring_1997"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1998">
            <WMSTileLayer
              layers="spring_1998"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 1999">
            <WMSTileLayer
              layers="spring_1999"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2000">
            <WMSTileLayer
              layers="spring_2000"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2001">
            <WMSTileLayer
              layers="spring_2001"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2002">
            <WMSTileLayer
              layers="spring_2002"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2003">
            <WMSTileLayer
              layers="spring_2003"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2004">
            <WMSTileLayer
              layers="spring_2004"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2005">
            <WMSTileLayer
              layers="spring_2005"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2006">
            <WMSTileLayer
              layers="spring_2006"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2007">
            <WMSTileLayer
              layers="spring_2007"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2008">
            <WMSTileLayer
              layers="spring_2008"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2009">
            <WMSTileLayer
              layers="spring_2009"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2010">
            <WMSTileLayer
              layers="spring_2010"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2011">
            <WMSTileLayer
              layers="spring_2011"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2012">
            <WMSTileLayer
              layers="spring_2012"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2013">
            <WMSTileLayer
              layers="spring_2013"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2014">
            <WMSTileLayer
              layers="spring_2014"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2015">
            <WMSTileLayer
              layers="spring_2015"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Water Level Contours - Spring 2016">
            <WMSTileLayer
              layers="spring_2016"
              transparent="true"
              format="image/png"
              map="/var/www/sgmadms/maps/map.map"
              url="https://dms2.houstoneng.net/cgi-bin/mapserv"
            />
          </LayersControl.Overlay>
        </LayersControl>
      </Map>
    );
  }
}

export default CreateMap;
