import React, { Component } from "react";
import { connect } from "react-redux";
import AppContainer from "../common/AppContainer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FileLoader from "./FileLoader";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { createSelector } from "../common/orm";
import BreadcrumbNav from "../common/BreadCrumb";
import LinearProgress from "@material-ui/core/LinearProgress";
import { mdiCheckCircle, mdiAlertCircle } from "@mdi/js";
import Icon from "@mdi/react";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "../common/TableCell";
import {
  WellSiteMeasurement,
  WaterQualityMeasurement,
  SubsidenceMeasurement,
} from "../wells/models";

import { SurfaceWaterMeasurement } from "../administration/models";

const styles = (theme) => ({
  container: {
    paddingLeft: "10px",
  },
  lgHeader: {
    fontSize: "25px",
  },
  btnValidateInactive: {
    marginTop: "10px",
    backgroundColor: "#7ed2f5",
    color: "#fff !important",
  },
  btnValidateActive: {
    marginTop: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff !important",
  },
  progressActive: {
    display: "block",
  },
  progressInactive: {
    display: "none",
  },
});

const getUserData = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["id"]),
  }),
  (session, options) => {
    let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];
    let association = session.GSPAssociation.filter(
      (u) => u.user === options.id && u.gsp === options.gsp
    ).toRefArray()[0];
    let gsp = session.GSP.filter(
      (u) => u.id === association.gsp
    ).toRefArray()[0];

    return user && association && gsp
      ? {
          ...user,
          association: association ? association : {},
          gsp: gsp ? gsp : {},
        }
      : {};
  }
);

function formatDate(date) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var day = date.getUTCDate();
  var monthIndex = date.getUTCMonth();
  var year = date.getUTCFullYear();

  return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector(
  (state, ownProps) => new Date().getFullYear(),
  (session, yr) => {
    let thisWaterYear = session.WaterYear.filter(
      (y) => y.water_year === yr
    ).toRefArray()[0];
    return thisWaterYear
      ? {
          ...thisWaterYear,
          water_year_start: formatDate(
            new Date(thisWaterYear.water_year_start)
          ),
          water_year_end: formatDate(new Date(thisWaterYear.water_year_end)),
        }
      : {};
  }
);

class StatusBar extends Component {
  render() {
    /* GET /datawizard/[id]/status.json?task=[task] */
    const { status, data, colErrors, import_target } = this.props;

    if (status === "SUCCESS") {
      if (data.skipped !== undefined && data.skipped.length > 0) {
        return (
          <div>
            <h2>
              {data.current} of {data.total} records were read, but{" "}
              {data.skipped.length} records did not upload due to the following
              errors:
            </h2>
            <br />
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Data Row</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Column</b>
                  </TableCell>
                  <TableCell>
                    <b>Error Message</b>
                  </TableCell>
                </TableRow>
                {data.skipped.map(function (row) {
                  const d = JSON.parse(row.reason);
                  return Object.keys(d).map((key) => {
                    return (
                      <TableRow>
                        <TableCell>{row.row}</TableCell>
                        <TableCell>{key}</TableCell>
                        <TableCell>{d[key]}</TableCell>
                      </TableRow>
                    );
                  });
                })}
              </Table>
            </Grid>
          </div>
        );
      } else {
        return (
          <div>
            {" "}
            {data.current} of {data.total} Records Loaded
          </div>
        );
      }
    } else if (status === "PROGRESS") {
      return (
        <div>
          {" "}
          <div>
            {data.current} of {data.total} Records Uploading.
          </div>
        </div>
      );
    } else if (status === "FAILURE") {
      if (colErrors.length) {
        return (
          <>
            <div>
              <h2>Column Mapping Failure:</h2>
            </div>
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Column Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Error Message</b>
                  </TableCell>
                </TableRow>
                {colErrors.map(function (col) {
                  return (
                    <TableRow>
                      <TableCell>{col.name}</TableCell>
                      <TableCell>{col.match}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid>

            <div>
              <h2>Acceptable column values for this dataset are:</h2>
            </div>
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Column ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Column Label</b>
                  </TableCell>
                </TableRow>
                {colErrors.length &&
                  colErrors[0].types.length &&
                  colErrors[0].types[0].choices.map(function (col) {
                    return (
                      <TableRow>
                        <TableCell>{col.id}</TableCell>
                        <TableCell>{col.label}</TableCell>
                      </TableRow>
                    );
                  })}
              </Table>
            </Grid>
          </>
        );
      } else {
        if (status !== undefined) {
          if (data && data.error !== undefined) {
            return (
              <div>
                <h2>
                  {status} : {data.error}
                </h2>
              </div>
            );
          } else if (data && data.detail !== undefined) {
            return (
              <div>
                <h2>
                  {status} : {data.detail}
                </h2>
              </div>
            );
          } else {
            return (
              <div>
                <h2>{status}</h2>
              </div>
            );
          }
        } else {
          return (
            <div>
              <h2>{data.error}</h2>
            </div>
          );
        }
      }
    } else {
      if (status !== undefined) {
        return <div> {status} </div>;
      } else {
        return <div> {data.error} </div>;
      }
    }
  }
}

class FormWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrftoken: localStorage.auth_token,
      loader: null,
      data: [],
      status: "Not Started",
      colErrors: [],
      progressType: "indeterminate",
      isImportButtonActive: false,
      isProgressActive: false,
      completed: 0,
    };
  }

  setAuthorized = () => {
    const { user, authState } = this.props;
    const { authorized } = this.state;

    if (
      user &&
      user.association &&
      user.association.authorized_reporter &&
      authState
    ) {
      if (!authorized) {
        this.setState({
          authorized: true,
          csrftoken: authState.user.auth_token,
          isImportButtonActive: false,
        });
      }
    }
  };

  onUpload = (object_id) => {
    console.log("Uploaded object with id: " + object_id);
    this.setState({
      object_id: object_id,
      isImportButtonActive: false,
      data: null,
      status: "Not Started",
    });
  };

  viewRecords = (data_id) => {
    console.log("Running Status Check");
    fetch("/datawizard/" + data_id + "/records", {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        /* Data is coming back as "Status:UNKNOWN" which is probably pending. */
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };
  trackRunStatus = (data_id, task_id) => {
    console.log("Running Status Check");
    fetch("/datawizard/" + data_id + "/status.json?task=" + task_id, {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          data: data,
          status: data.status,
        });

        if (["SUCCESS", "FAILURE"].indexOf(data.status) > -1) {
          this.setState({
            isProgressActive: false,
          });

          if (data.status === "SUCCESS") {
            if (
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.import_target
            ) {
              let import_target = this.props.location.state.import_target;
            }
          }
        } else if (["PROGRESS"].indexOf(data.status) > -1) {
          this.setState({ completed: (data.current / data.total) * 100 });

          setTimeout(() => {
            this.trackRunStatus(data_id, task_id);
          }, 1000);
        }
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };
  getRunColumns = (data_id) => {
    fetch("/datawizard/" + data_id + "/columns", {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.result && data.result.unknown_count > 0) {
          this.setState({
            colErrors: data.result.columns,
          });
        }

        this.setState({ progressType: "determinate" });
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };

  startDataRun = () => {
    const { content_type_id, serializer } = this.props.location.state;

    this.setState({
      isImportButtonActive: true,
      isProgressActive: true,
    });

    console.log(this.state);

    fetch("/datawizard.json", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        object_id: this.state.object_id,
        content_type_id: content_type_id,
        serializer: serializer,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.id !== null && data.id !== undefined) {
          this.setState({ object_id: data.id });
          this.runData(data.id);
        } else {
          this.setState({
            isImportButtonActive: false,
            isProgressActive: false,
            status: "FAILURE",
            data: data,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };

  runData = (data_id) => {
    fetch("/datawizard/" + data_id + "/data", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ task_id: data.task_id });
        console.log(data);
        if (data.task_id !== null && data.task_id !== undefined) {
          this.getRunColumns(data.id);
          setTimeout(() => {
            this.trackRunStatus(data.id, data.task_id);
          }, 3000);
        }
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };

  /* NOTES
   * 1. Some keys may not match right away. These can be matched manually in the data_wizard_identifier table.
   * 2. GET /datawizard/[id]/columns will indicate any failed fields.
   * 3. GET /datawizard/[id]/status.json?[task_id] will indicate columns skipped. These need to be identified and shown to user
   * 4. Validation should be queued off of the status where skipped fields are displayed.
   * 5. A successful GET /datawizard/[id]/status.json?[task_id] will display total and the url /datawizard/[id]/records to display the records imported. These need to be captured and displayed to the user.
   * 6. GET /datawizard/[id]/status.json?[task_id] should generate buttons to check updated status
   * 7.
   */

  render() {
    this.setAuthorized();
    const {
      object_id,
      task_id,
      data,
      status,
      authorized,
      isImportButtonActive,
      isProgressActive,
      completed,
      progressType,
      colErrors,
    } = this.state; // csrftoken, task,
    const { classes, wateryr, user } = this.props; //, content_type_id='sgmadms.measurement_records'

    const import_target = this.props.location.state
      ? this.props.location.state.import_target
      : "Data Import";

    if (import_target === "Data Import") {
      //need to have state from import page. if directly linked to, redirect back to import page.
      this.props.history.push({ pathname: "/import" });
    }

    return (
      <AppContainer authenticated>
        <div className={classes.container}>
          <BreadcrumbNav
            level1="Import Monitoring Data"
            level1link="/import"
            level2={import_target}
            level2link="/wizard"
          />
          <br />
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <span className={classes.lgHeader}>
                Current Water Year {wateryr ? wateryr.water_year : ""}:{" "}
                {wateryr ? wateryr.water_year_start : ""} -{" "}
                {wateryr ? wateryr.water_year_end : ""}
              </span>
            </Grid>
            <Grid item xs={12}>
              <b>Role:</b> Your user account is{" "}
              {authorized ? "currently" : <b>NOT</b>} authorized to report data
              for:{" "}
              <b>
                {user && user.gsp
                  ? user.gsp.name
                  : "(User not associated with GSP)"}
              </b>
              .
            </Grid>
            <Grid item xs={12}>
              <b>Upload Instructions:</b>
              <ol>
                <li>
                  Please use the Spreadsheet Template designed for the dataset
                  you are uploading (available on the Import Monitoring Data
                  page).{" "}
                </li>
                <li>Please do not alter the column names or order. </li>
                <li>
                  Please make sure the upload data is in the first tab of the
                  spreadsheet.
                </li>
              </ol>
              <b>Validation Requirements:</b>
              <ol>
                <li>Water Year must be valid and exist.</li>
                <li>
                  DMS Site Id must be associated with a GSP that the user is
                  authorized to submit data for.
                </li>
                <li>
                  Records must be unique. A record is considered a duplicate if
                  there is more than one per/day for a single DMS Site Id.
                </li>
              </ol>
            </Grid>
          </Grid>
          <br />
          <LinearProgress
            variant={progressType}
            className={
              isProgressActive
                ? classes.progressActive
                : classes.progressInactive
            }
            value={completed}
          />
          <br />
          <FileLoader
            postURL="/filemodels.json"
            csrftoken={this.state.csrftoken}
            onUpload={this.onUpload}
          />

          <Button
            className={
              object_id === undefined || isImportButtonActive
                ? classes.btnValidateInactive
                : classes.btnValidateActive
            }
            disabled={object_id === undefined || isImportButtonActive}
            onClick={this.startDataRun}
          >
            {" "}
            Begin Validate and Import{" "}
          </Button>

          <Icon
            style={{
              paddingLeft: "15px",
              verticalAlign: "middle",
              visibility:
                status == "SUCCESS" || status == "FAILURE"
                  ? "visible"
                  : "hidden",
            }}
            path={
              status == "SUCCESS" && data.skipped && data.skipped.length == 0
                ? mdiCheckCircle
                : mdiAlertCircle
            }
            size={2}
            color={
              status == "SUCCESS" && data.skipped && data.skipped.length == 0
                ? "#28a745"
                : "#b20000"
            }
          ></Icon>

          <StatusBar
            object_id={object_id}
            task_id={task_id}
            completed={completed}
            trackRunStatus={this.trackRunStatus.bind(this)}
            viewRecords={this.viewRecords.bind(this)}
            status={status}
            data={data}
            colErrors={colErrors}
            import_target={import_target}
          />
        </div>
      </AppContainer>
    );
  }
}
FormWizard = connect(
  (state, ownProps) => ({
    wateryr: getWaterYear(state, ownProps),
    user: getUserData(state, ownProps),
    authState: state.auth,
  }),
  {
    ...WellSiteMeasurement.actions,
    ...WaterQualityMeasurement.actions,
    ...SurfaceWaterMeasurement.actions,
    ...SubsidenceMeasurement.actions,
  }
)(FormWizard);
export default withStyles(styles)(withRouter(FormWizard));
