import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import BreadcrumbNav from "../common/BreadCrumb";

import AppContainer from "../common/AppContainer";
import Button from "@material-ui/core/Button";
import Icon from "@mdi/react";
import { mdiSwapVertical, mdiDownload, mdiUpload } from "@mdi/js";
import { createSelector } from "../common/orm";
import {
  WellSiteMeasurement,
  WaterQualityMeasurement,
  SubsidenceMeasurement,
} from "../wells/models";
import NotReady from "../common/NotReady";
import ReportingStatus from "../../api/reportingStatusHOC";
import { SurfaceWaterMeasurement } from "../administration/models";
import ImportSubmitButton from "../../api/ImportSubmitButtonHOC";
import requestConfig from "../../api/fetchConfig";

const styles = (theme) => ({
  buttons: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  container: {
    paddingLeft: "10px",
  },
  hrclass: {
    borderColor: theme.palette.primary.main,
  },
  lgHeader: {
    fontSize: "25px",
  },
  mdHeader: {
    fontSize: "18px",
    textDecoration: "underline",
    fontWeight: "normal",
  },
});

class ImportMonitoringData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrftoken: localStorage.auth_token,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (authState && authState.user && authState.user.role === "Agency") {
      history.push("/dashboard");
    }
  }

  handleAPIChange(data) {
    if (data.user_role === "GSP Representative") {
      this.setState({ authorized: true });
    }

    let gsp_id = parseInt(this.props.match.params["id"]);
    let cy_data = data.gsp.filter((g) => g.id === gsp_id);

    if (cy_data.length) {
      this.setState({
        cy_data: cy_data[0],
        water_year: data.water_year,
        gsp_id: gsp_id,
      });
    }
  }

  setAuthorized = () => {
    const { cy_data, authState } = this.props;
    const { authorized } = this.state;

    if (cy_data && cy_data.ass && cy_data.ass.authorized_reporter) {
      if (
        !authorized &&
        authState &&
        authState.user &&
        authState.user.role === "GSP Representative"
      ) {
        this.setState({ authorized: true });
      }
    }
  };

  submitMonitoringData = (e) => {
    console.log(e);
    console.log("Monitoring Data Submitted");
    this.setState({
      all_sumitted: false,
    });
  };

  startImportWizard = (section) => {
    let wurl = "/wizard/" + this.props.match.params["id"];

    switch (section) {
      case "gw":
        console.log("Groundwater");
        this.props.history.push({
          pathname: wurl,
          state: {
            content_type_id: "wells.filemodel",
            serializer: "wells.serializers.WellSiteMeasurementSerializer",
            import_target: "Water Level Data",
          },
        });
        break;
      case "wq":
        console.log("Water Quality");
        this.props.history.push({
          pathname: wurl,
          state: {
            content_type_id: "wells.filemodel",
            serializer: "wells.serializers.WaterQualityMeasurementSerializer",
            import_target: "Water Quality Data",
          },
        });
        break;
      case "surf":
        console.log("Surface");
        this.props.history.push({
          pathname: wurl,
          state: {
            content_type_id: "wells.filemodel",
            serializer: "wells.serializers.SurfaceWaterMeasurementSerializer",
            import_target: "Interconnected SW Monitoring Data",
          },
        });
        break;
      case "sub":
        console.log("Subsidence");
        this.props.history.push({
          pathname: wurl,
          state: {
            content_type_id: "wells.filemodel",
            serializer: "wells.serializers.SubsidenceMeasurementSerializer",
            import_target: "Subsidence Data",
          },
        });
        break;
      case "stream":
        console.log("Stream");
        this.props.history.push({
          pathname: wurl,
          state: {
            content_type_id: "wells.filemodel",
            serializer: "wells.serializers.StreamMonitoringSerializer",
            import_target: "Stream Monitoring Data",
          },
        });
        break;
      default:
        break;
    }
  };

  downloadTemplate = (fileName) => {
    window.open(
      "https://dms2.houstoneng.net/media/templates/" + fileName,
      "_newtab"
    );
  };

  downloadImportTemplate = (section) => {
    switch (section) {
      case "gw":
        this.downloadTemplate("water_level_data.xlsx");
        break;
      case "wq":
        this.downloadTemplate("water_quality_data.xlsx");
        break;
      case "surf":
        this.downloadTemplate("sw_gw_interaction_data.xlsx");
        break;
      case "sub":
        this.downloadTemplate("subsidence_data.xlsx");
        break;
      case "stream":
        this.downloadTemplate("stream_monitoring_data.xlsx");
        break;
      default:
        break;
    }
  };

  render() {
    const {
      authorized,
      cy_data,
      gsp_id,
      water_year,
      appConfig,
      isFetching,
    } = this.state;
    const { classes, authState } = this.props;

    let token = authState && authState.user ? authState.user.auth_token : "";

    //get the config data
    if (token !== "" && appConfig === undefined && !isFetching) {
      this.setState({ ...this.state, isFetching: true });
      requestConfig(token).then((data) => {
        this.setState({ appConfig: data, isFetching: false });
      });
    }

    if (cy_data === undefined || appConfig === undefined) {
      return (
        <AppContainer authenticated>
          <ReportingStatus
            apitarget={"combinedstatus"}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <div className={classes.container}>
            <BreadcrumbNav
              level1="Associated GSP's"
              level1link="/import"
              level2="Import Monitoring Data"
              level2link={"/import/" + this.props.match.params["id"]}
            />
            <br />
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <span className={classes.lgHeader}>
                  Current Water Year {water_year ? water_year.water_year : ""}:{" "}
                  {water_year ? water_year.water_year_start_lbl : ""} -{" "}
                  {water_year ? water_year.water_year_end_lbl : ""}
                </span>
              </Grid>
              <Grid item xs={12}>
                <b>Role:</b> Your user account is{" "}
                {authorized ? "currently" : <b>NOT</b>} authorized to report
                data for:{" "}
                <b>
                  {cy_data && cy_data
                    ? cy_data.name
                    : "(User not associated with GSP)"}
                </b>
                .
              </Grid>
              {cy_data.gw_required === true &&
              appConfig.WLM.enabled === true ? (
                <Grid item xs={12}>
                  <hr className={classes.hrclass} />
                  <h2 className={classes.mdHeader}>Water Level Data</h2>
                  <p>
                    <b>Import Status:</b>{" "}
                    {cy_data && cy_data.gw_ct ? (
                      cy_data.gw_ct.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) + " Record(s) have "
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been imported into the DMS for the current water year.{" "}
                    {cy_data && cy_data.gw_ct && authorized ? (
                      <a
                        href={
                          "/#/importMonitoringHistory/wellsitemeasurements/" +
                          gsp_id
                        }
                      >
                        View Existing Data
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    <b>Submission Status :</b>{" "}
                    {cy_data && cy_data.gw_submitted === "Complete" ? (
                      <>Data has </>
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been submitted for the current water year.
                  </p>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.startImportWizard("gw")}
                    disabled={
                      !authorized ||
                      (cy_data && cy_data.gw_submitted === "Complete")
                    }
                    style={{
                      backgroundColor:
                        authorized &&
                        cy_data &&
                        cy_data.gw_submitted !== "Complete"
                          ? "primary"
                          : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiSwapVertical} size={1} color="white"></Icon>{" "}
                    Start Import Wizard
                  </Button>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.downloadImportTemplate("gw")}
                    disabled={!authorized}
                    style={{
                      backgroundColor: authorized ? "primary" : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiDownload} size={1} color="white"></Icon>{" "}
                    Download Spreadsheet Template
                  </Button>
                  <ImportSubmitButton
                    dataTarget="gw"
                    data={{ cy_data }}
                    gsp={gsp_id}
                    handler={this.handleAPIChange.bind(this)}
                  ></ImportSubmitButton>
                </Grid>
              ) : (
                <></>
              )}
              {cy_data.wq_required === true &&
              appConfig.WQM.enabled === true ? (
                <Grid item xs={12}>
                  <hr className={classes.hrclass} />
                  <h2 className={classes.mdHeader}>Water Quality Data</h2>
                  <p>
                    <b>Import Status:</b>{" "}
                    {cy_data && cy_data.wq_ct ? (
                      cy_data.wq_ct.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) + " Record(s) have "
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been imported into the DMS for the current water year.{" "}
                    {cy_data && cy_data.wq_ct && authorized ? (
                      <a
                        href={
                          "/#/importMonitoringHistory/waterqualitymeasurements/" +
                          gsp_id
                        }
                      >
                        View Existing Data
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    <b>Submission Status :</b>{" "}
                    {cy_data && cy_data.wq_submitted === "Complete" ? (
                      <>Data has </>
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been submitted for the current water year.
                  </p>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.startImportWizard("wq")}
                    disabled={
                      !authorized ||
                      (cy_data && cy_data.wq_submitted === "Complete")
                    }
                    style={{
                      backgroundColor:
                        authorized &&
                        cy_data &&
                        cy_data.wq_submitted !== "Complete"
                          ? "primary"
                          : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiSwapVertical} size={1} color="white"></Icon>{" "}
                    Start Import Wizard
                  </Button>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.downloadImportTemplate("wq")}
                    disabled={!authorized}
                    style={{
                      backgroundColor: authorized ? "primary" : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiDownload} size={1} color="white"></Icon>{" "}
                    Download Spreadsheet Template
                  </Button>
                  <ImportSubmitButton
                    dataTarget="wq"
                    data={{ cy_data }}
                    gsp={gsp_id}
                    handler={this.handleAPIChange.bind(this)}
                  ></ImportSubmitButton>
                </Grid>
              ) : (
                <></>
              )}
              {cy_data.surf_required === true &&
              appConfig.ISM.enabled === true ? (
                <Grid item xs={12}>
                  <hr className={classes.hrclass} />
                  <h2 className={classes.mdHeader}>
                    Interconnected SW Monitoring Data
                  </h2>
                  <p>
                    <b>Import Status:</b>{" "}
                    {cy_data && cy_data.surf_ct ? (
                      cy_data.surf_ct.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) + " Record(s) have "
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been imported into the DMS for the current water year.{" "}
                    {cy_data && cy_data.surf_ct && authorized ? (
                      <a
                        href={
                          "/#/importMonitoringHistory/surfacewatermeasurements/" +
                          gsp_id
                        }
                      >
                        View Existing Data
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    <b>Submission Status :</b>{" "}
                    {cy_data && cy_data.surf_submitted === "Complete" ? (
                      <>Data has </>
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been submitted for the current water year.
                  </p>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.startImportWizard("surf")}
                    disabled={
                      !authorized ||
                      (cy_data && cy_data.surf_submitted === "Complete")
                    }
                    style={{
                      backgroundColor:
                        authorized &&
                        cy_data &&
                        cy_data.surf_submitted !== "Complete"
                          ? "primary"
                          : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiSwapVertical} size={1} color="white"></Icon>{" "}
                    Start Import Wizard
                  </Button>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.downloadImportTemplate("surf")}
                    disabled={!authorized}
                    style={{
                      backgroundColor: authorized ? "primary" : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiDownload} size={1} color="white"></Icon>{" "}
                    Download Spreadsheet Template
                  </Button>
                  <ImportSubmitButton
                    dataTarget="surf"
                    data={{ cy_data }}
                    gsp={gsp_id}
                    handler={this.handleAPIChange.bind(this)}
                  ></ImportSubmitButton>
                </Grid>
              ) : (
                <></>
              )}
              {cy_data.sub_required === true &&
              appConfig.SM.enabled === true ? (
                <Grid item xs={12}>
                  <hr className={classes.hrclass} />
                  <h2 className={classes.mdHeader}>
                    Subsidence Monitoring Data
                  </h2>
                  <p>
                    <b>Import Status:</b>{" "}
                    {cy_data && cy_data.sub_ct ? (
                      cy_data.sub_ct.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) + " Records have "
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been imported into the DMS for the current water year.{" "}
                    {cy_data && cy_data.sub_ct && authorized ? (
                      <a
                        href={
                          "/#/importMonitoringHistory/subsidencemeasurements/" +
                          gsp_id
                        }
                      >
                        View Existing Data
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    <b>Submission Status :</b>{" "}
                    {cy_data && cy_data.sub_submitted === "Complete" ? (
                      <>Data has </>
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been submitted for the current water year.
                  </p>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.startImportWizard("sub")}
                    disabled={
                      !authorized ||
                      (cy_data && cy_data.sub_submitted === "Complete")
                    }
                    style={{
                      backgroundColor:
                        authorized &&
                        cy_data &&
                        cy_data.sub_submitted !== "Complete"
                          ? "primary"
                          : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiSwapVertical} size={1} color="white"></Icon>{" "}
                    Start Import Wizard
                  </Button>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.downloadImportTemplate("sub")}
                    disabled={!authorized}
                    style={{
                      backgroundColor: authorized ? "primary" : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiDownload} size={1} color="white"></Icon>{" "}
                    Download Spreadsheet Template
                  </Button>
                  <ImportSubmitButton
                    dataTarget="sub"
                    data={{ cy_data }}
                    gsp={gsp_id}
                    handler={this.handleAPIChange.bind(this)}
                  ></ImportSubmitButton>
                </Grid>
              ) : (
                <></>
              )}
              {cy_data.stream_required === true &&
              appConfig.ADMIN.STMS.enabled === true ? (
                <Grid item xs={12}>
                  <hr className={classes.hrclass} />
                  <h2 className={classes.mdHeader}>Stream Monitoring Data</h2>
                  <p>
                    <b>Import Status:</b>{" "}
                    {cy_data && cy_data.stream_ct ? (
                      cy_data.stream_ct.toLocaleString(navigator.language, {
                        minimumFractionDigits: 0,
                      }) + " Records have "
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been imported into the DMS for the current water year.{" "}
                    {cy_data && cy_data.stream_ct && authorized ? (
                      <a
                        href={
                          "/#/importMonitoringHistory/streammonitoring/" +
                          gsp_id
                        }
                      >
                        View Existing Data
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p>
                    <b>Submission Status :</b>{" "}
                    {cy_data && cy_data.stream_submitted === "Complete" ? (
                      <>Data has </>
                    ) : (
                      <>
                        Data has <b>not</b>{" "}
                      </>
                    )}{" "}
                    been submitted for the current water year.
                  </p>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.startImportWizard("stream")}
                    disabled={
                      !authorized ||
                      (cy_data && cy_data.stream_submitted === "Complete")
                    }
                    style={{
                      backgroundColor:
                        authorized &&
                        cy_data &&
                        cy_data.stream_submitted !== "Complete"
                          ? "primary"
                          : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiSwapVertical} size={1} color="white"></Icon>{" "}
                    Start Import Wizard
                  </Button>
                  <Button
                    className={classes.buttons}
                    onClick={() => this.downloadImportTemplate("stream")}
                    disabled={!authorized}
                    style={{
                      backgroundColor: authorized ? "primary" : "#7ed2f5",
                      color: "white",
                    }}
                  >
                    <Icon path={mdiDownload} size={1} color="white"></Icon>{" "}
                    Download Spreadsheet Template
                  </Button>
                  <ImportSubmitButton
                    dataTarget="stream"
                    data={{ cy_data }}
                    gsp={gsp_id}
                    handler={this.handleAPIChange.bind(this)}
                  ></ImportSubmitButton>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </div>
        </AppContainer>
      );
    }
  }
}

ImportMonitoringData = connect(
  (state, ownProps) => ({
    authState: state.auth,
  }),
  {
    ...WellSiteMeasurement.actions,
    ...WaterQualityMeasurement.actions,
    ...SurfaceWaterMeasurement.actions,
    ...SubsidenceMeasurement.actions,
  }
)(ImportMonitoringData);
export default withStyles(styles)(withRouter(ImportMonitoringData));
