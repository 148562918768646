const map_initial_state = {
  lat: 37.09751145009801,
  lng: -121.0200191684891,
  zoom: 14,
  collapsed: false,
  showLayerList: true,
};

const map_layers = [
  {
    id: "esri_topographic",
    add: true,
    checked: true,
    type: "baselayer",
    type2: "topographic",
    name: "ESRI Topographic",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_streets",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "street",
    name: "ESRI Streets",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "esri_imagery",
    add: true,
    checked: false,
    type: "baselayer",
    type2: "imagery",
    name: "ESRI Imagery",
    attribution: "ESRI",
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "water_level_sites",
    add: true,
    checked: true,
    title: "Water Level Sites",
    type: "maplayer",
    type2: "water_level_sites",
    page_type: "Water Level",
    link: "waterleveldetail/",
    color: {
      hex: "#CF382D",
      rgb: "red",
    },
    markerSize: 8,
    identify: true,
    index: 0,
  },
  {
    id: "water_meter_sites",
    add: true,
    checked: true,
    title: "Water Meter Sites",
    type: "maplayer",
    type2: "water_meter_sites",
    page_type: "Water Meter Sites",
    link: "watermeterdetail/",
    color: {
      hex: "#406470",
      rgb: "royalblue",
    },
    markerSize: 8,
    identify: true,
    index: 0,
  },
  {
    id: "santa_nella_county_wd",
    add: true,
    title: "Santa Nella County Water District Boundary",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Boundary", color: "rgb(75,75,75)" }],
    layers: "santa_nella_county_wd",
    map: "/var/www/santanella/maps/map.map",
    url: "https://dms2.houstoneng.net/cgi-bin/mapserv",
    index: 5,
  },
];

export { map_initial_state, map_layers };
