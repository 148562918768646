import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";

import Header from "./Header";
import Navigation from "./Navigation";
import NotReady from "./NotReady";

const styles = theme => ({
    appContainer: {
        // The parent container of the header, sidebar, content
        paddingTop: 50, // 64 = Height of Header; + 16 padding
        height: "calc(100% - 50px)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    contentContainer: {
        // The sibling container to the header, sidebar that contains the main content
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        "& > div:first-child, & > form:first-child": {
            // Set the padding to the footer on the first child for the content container
            //paddingBottom: 60 // 32 = Height of Footer; + 8 padding + 20 extra
        },
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form div:first-child": {
            width: "calc(100% - 40px)",
            marginTop: 0, // Assuming spacing = 24 on parent grid container
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 0
        }
    },
    backgroundColor: {
        backgroundColor: "#fff"
    },
    contentContainerOpen: {
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 325 // 325 = Width of Sidebar
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: 275 // 325 = Width of Sidebar
        }
    },
    contentContainerClosed: {
        paddingLeft: theme.spacing.unit * 7 // Width of Closed Sidebar
    },
    height: {
        height: "100%"
    }
});

class AppContainer extends Component {
    render() {
        const { children, classes, authenticated, navState, authState, synced } = this.props;

        return authenticated ? ( // Logged In
            <div className={classNames(classes.appContainer, classes.backgroundColor)}>
                {/* authenticated shows top menu app */}
                <Header authenticated />
                <Navigation />
                <div
                    className={classNames(
                        classes.contentContainer,
                        classes.height,
                        navState.navigationOpen ? classes.contentContainerOpen : classes.contentContainerClosed
                    )}>
                    {authState.user ? (
                        !synced ? (
                            children
                        ) : (
                            <NotReady message="Loading..." />
                        )
                    ) : (
                        <NotReady storeReady={navState.storeReady} />
                    )}
                </div>
            </div>
        ) : (
            // Public
            <div className={classNames(classes.appContainer, classes.backgroundColor)}>
                <Header /> {/* no props for authenticated doesnt show top menu app */}
                <div className={classes.height}>{children}</div>
            </div>
        );
    }
}

AppContainer = connect(state => ({
    navState: state.nav,
    authState: state.auth
}))(AppContainer);

export default withStyles(styles)(AppContainer);
