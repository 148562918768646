import React from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { GSP } from "./models";
import { mdiUndo, mdiPlus } from "@mdi/js";

const allGSPs = GSP.selectAll("name");

const styles = theme => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee"
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)"
    }
  },
  centerAlign: {
    textAlign: "center"
  }
});

const columnData = [
  { id: "actions", numeric: false, label: "Actions", allowSort: false },
  { id: "name", numeric: false, label: "Name", allowSort: true },
  {
    id: "abbreviation",
    numeric: false,
    label: "Abbreviation",
    allowSort: true
  },
  { id: "id", numeric: false, label: "ID", allowSort: false }
];

class AdminGSPs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      order: "asc",
      orderBy: null,
      page: 0,
      rowsPerPage: 25,
      showDelete: false,
      deleteId: null,
      deleteName: null
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  filterByValue = (array, string) => {
    return array.filter(o =>
      Object.keys(o).some(
        k =>
          o[k] &&
          o[k]
            .toString()
            .toLowerCase()
            .includes(string.toLowerCase())
      )
    );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    order === "desc"
      ? this.props.gsps.sort((a, b) =>
          b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
        )
      : this.props.gsps.sort((a, b) =>
          a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
        );

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  render() {
    const { classes, history, gsps, ormGSPDelete } = this.props;
    const {
      order,
      orderBy,
      filter,
      page,
      rowsPerPage,
      showDelete,
      deleteName,
      deleteId
    } = this.state;

    var filtered_items;
    if (filter) {
      filtered_items = this.filterByValue(gsps, filter);
    } else {
      filtered_items = gsps;
    }

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage GSPs"
              level2link="/admingsps"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/administration")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Administration
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Keyword Filter</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={9}
            style={{ backgroundColor: "#ddd", marginLeft: 12 }}
          >
            <Form dontValidateOnMount={true} validateOnSubmit={true}>
              {formApi => (
                <form style={{ float: "left" }} onSubmit={formApi.submitForm}>
                  <TextField
                    rounded
                    style={{ borderRadius: 114 }}
                    placeholder="Filter GSPs"
                    startAdornment={
                      <InputAdornment style={{ marginRight: 8 }}>
                        <SearchIcon />
                      </InputAdornment>
                    }
                    eventHandle={val => {
                      this.setState({ filter: val });
                    }}
                    field="filter"
                    label=""
                  />
                </form>
              )}
            </Form>
          </Grid>
          <Grid item xs={6} lg={2} style={{ marginTop: 8 }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/admingsps/new")}
            >
              <Icon path={mdiPlus} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Add GSP
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <EnhancedTableHead
                columnData={columnData}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {filtered_items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Tooltip title="Edit GSP">
                          <Button
                            className={classes.deleteWidth}
                            component={Link}
                            to={"/admingsps/" + item.id}
                          >
                            <EditIcon color="primary" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete GSP">
                          <Button
                            className={classes.deleteWidth}
                            onClick={() =>
                              this.setState({
                                deleteName: item.name,
                                deleteId: item.id,
                                showDelete: true
                              })
                            }
                          >
                            <DeleteIcon style={{ color: "#b20000" }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.abbreviation}</TableCell>
                      <TableCell>{item.id}</TableCell>
                    </TableRow>
                  ))}
                {filtered_items.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={4} className={classes.centerAlign}>
                      No GSPs Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {filtered_items.length > 25 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={4}
                      count={filtered_items.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Grid>
        </Grid>
        <WarningDialog
          confirmAction={() => {
            ormGSPDelete(deleteId);
            this.setState({ showDelete: false });
          }}
          cancelAction={() => this.setState({ showDelete: false })}
          open={showDelete}
          title="Delete GSP"
          confirmText="Delete GSP"
          text={"Are you sure you wish to delete " + deleteName + "?"}
        />
      </AppContainer>
    );
  }
}

AdminGSPs = connect(
  (state, ownProps) => ({
    gsps: allGSPs(state),
    authState: state.auth
  }),
  {
    ...GSP.actions
  }
)(AdminGSPs);
export default withStyles(styles)(withRouter(AdminGSPs));
