import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@mdi/react";
import { WaterYear } from "./models";
import { mdiUndo, mdiFileDocument } from "@mdi/js";

const allWaterYears = WaterYear.selectAll();

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://santanella.houstoneng.net";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16,
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  checkAll: {
    marginTop: "-13px",
  },
  checkAllLbl: {
    paddingTop: "10px",
  },
  progressActive: {
    display: "block",
  },
  progressInactive: {
    display: "none",
  },
  selectWaterYrLbl: {
    marginLeft: "15px",
    bottom: "6px",
  },
});

class AdminEditWaterYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      waterYear: null,
      waterYearInit: false,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  handleChangeWaterYear = (event) => {
    this.setState({ waterYear: event.target.value });
  };

  initWaterYear() {
    const { wateryr } = this.props;
    const { waterYearInit } = this.state;

    if (!waterYearInit && wateryr.length > 0) {
      let activeId = wateryr.find((w) => w.is_active === true);

      this.setState({
        waterYear: activeId.id,
        waterYearInit: true,
      });
    }
  }

  setActiveWaterYear() {
    const { waterYear } = this.state;
    const {
      ormWaterYearUpdate,
      ormWaterYearUpdateLocalOnly,
      wateryr,
    } = this.props;

    wateryr.map((w) => {
      ormWaterYearUpdateLocalOnly({
        ...w,
        is_active: false,
      });
    });

    ormWaterYearUpdate({
      id: waterYear,
      is_active: true,
    });
  }

  render() {
    const { classes, history, wateryr } = this.props;
    const { checked, loading, waterYear } = this.state;

    this.initWaterYear();

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Set Water Year"
              level2link="/adminwateryear"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/administration")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Administration
            </Button>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress
              className={
                loading ? classes.progressActive : classes.progressInactive
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Select
                  id="selectWaterYear"
                  value={waterYear}
                  onChange={this.handleChangeWaterYear}
                  className={classes.selectWaterYrLbl}
                >
                  {wateryr
                    .sort((a, b) => (a.water_year < b.water_year ? 1 : -1))
                    .map((wy) => (
                      <MenuItem value={wy.id}>{wy.water_year}</MenuItem>
                    ))}
                </Select>
              }
              label="Water Year:"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                this.setActiveWaterYear();
              }}
            >
              Set DMS Water Year
            </Button>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }
}

AdminEditWaterYear = connect(
  (state, ownProps) => ({
    wateryr: allWaterYears(state),
    authState: state.auth,
  }),
  {
    ...WaterYear.actions,
  }
)(AdminEditWaterYear);
export default withStyles(styles)(withRouter(AdminEditWaterYear));
