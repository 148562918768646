import React from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import TextField from "../common/TextField";
import { createSelector } from "../common/orm";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { User } from "../administration/models";
import { mdiUndo, mdiPlus } from "@mdi/js";

const getUsers = createSelector(
  (state, ownProps) => parseInt(ownProps.match.params["id"]),
  (session, id) => {
    return session.User.all()
      .orderBy("email")
      .toModelArray()
      .map((user) => ({
        _associations: user.gsps.all().toRefArray(),
        ...user.ref,
      }));
  }
);

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16,
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
});

const columnData = [
  { id: "actions", numeric: false, label: "Actions", allowSort: false },
  { id: "first_name", numeric: false, label: "First Name", allowSort: true },
  { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
  { id: "city", numeric: false, label: "City", allowSort: true },
  { id: "email", numeric: false, label: "Email", allowSort: true },
  { id: "status", numeric: false, label: "Status", allowSort: true },
  { id: "phone", numeric: false, label: "Phone", allowSort: true },
  { id: "agency_label", numeric: false, label: "Agency", allowSort: true },
  { id: "role", numeric: false, label: "Role", allowSort: true },
  { id: "gsp", numeric: false, label: "GSP Association", allowSort: false },
];

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      order: "asc",
      orderBy: null,
      page: 0,
      rowsPerPage: 25,
      showDelete: false,
      deleteId: null,
      deleteName: null,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  filterByValue = (array, string) => {
    return array.filter((o) =>
      Object.keys(o).some(
        (k) =>
          o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
      )
    );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    order === "desc"
      ? this.props.users.sort((a, b) =>
          (b[orderBy] ? b[orderBy].toUpperCase() : "") <
          (a[orderBy] ? a[orderBy].toUpperCase() : "")
            ? -1
            : 1
        )
      : this.props.users.sort((a, b) =>
          (a[orderBy] ? a[orderBy].toUpperCase() : "") <
          (b[orderBy] ? b[orderBy].toUpperCase() : "")
            ? -1
            : 1
        );

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  render() {
    const { classes, history, users, ormUserDelete } = this.props;
    const {
      order,
      orderBy,
      filter,
      page,
      rowsPerPage,
      showDelete,
      deleteName,
      deleteId,
    } = this.state;

    var filtered_users;
    if (filter) {
      filtered_users = this.filterByValue(users, filter);
    } else {
      filtered_users = users;
    }
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage Users"
              level2link="/adminusers"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/administration")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Administration
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Keyword Filter</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={9}
            style={{ backgroundColor: "#ddd", marginLeft: 12 }}
          >
            <Form dontValidateOnMount={true} validateOnSubmit={true}>
              {(formApi) => (
                <form style={{ float: "left" }} onSubmit={formApi.submitForm}>
                  <TextField
                    rounded
                    style={{ borderRadius: 114 }}
                    placeholder="Filter Users"
                    startAdornment={
                      <InputAdornment style={{ marginRight: 8 }}>
                        <SearchIcon />
                      </InputAdornment>
                    }
                    eventHandle={(val) => {
                      this.setState({ filter: val });
                    }}
                    field="filter"
                    label=""
                  />
                </form>
              )}
            </Form>
          </Grid>
          <Grid item xs={6} lg={2} style={{ marginTop: 8 }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/adminusers/new")}
            >
              <Icon path={mdiPlus} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Add User
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <EnhancedTableHead
                columnData={columnData}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {filtered_users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Tooltip title="Edit User">
                          <Button
                            className={classes.deleteWidth}
                            component={Link}
                            to={"/adminusers/" + item.id}
                          >
                            <EditIcon color="primary" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete User">
                          <Button
                            className={classes.deleteWidth}
                            onClick={() =>
                              this.setState({
                                deleteName: item.email,
                                deleteId: item.id,
                                showDelete: true,
                              })
                            }
                          >
                            <DeleteIcon style={{ color: "#b20000" }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{item.first_name}</TableCell>
                      <TableCell>{item.last_name}</TableCell>
                      <TableCell>{item.city}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{item.phone}</TableCell>
                      <TableCell>{item.agency_label}</TableCell>
                      <TableCell>{item.role}</TableCell>
                      <TableCell>
                        {item._associations.map((a) => a.name).join(", ")}
                      </TableCell>
                    </TableRow>
                  ))}
                {filtered_users.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={10} className={classes.centerAlign}>
                      No Users Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {filtered_users.length > 25 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={10}
                      count={filtered_users.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Grid>
        </Grid>
        <WarningDialog
          confirmAction={() => {
            ormUserDelete(deleteId);
            this.setState({ showDelete: false });
          }}
          cancelAction={() => this.setState({ showDelete: false })}
          open={showDelete}
          title="Delete User"
          confirmText="Delete User"
          text={"Are you sure you wish to user: " + deleteName + "?"}
        />
      </AppContainer>
    );
  }
}
AdminUsers = connect(
  (state, ownProps) => ({
    users: getUsers(state, ownProps),
    authState: state.auth,
  }),
  {
    ...User.actions,
  }
)(AdminUsers);
export default withStyles(styles)(withRouter(AdminUsers));
