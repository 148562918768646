import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  submitWrapper: {
    position: "relative",
  },
  progress: {
    top: "50%",
    left: "50%",
    color: "#28a745",
    position: "absolute",
    marginTop: -12,
    marginLeft: -12,
  },
};

const Submit = ({
  label,
  subLabel,
  altAction,
  altLabel,
  classes,
  loading,
  gridSize = 12,
  justify = "center",
  greyButton = false,
  altAction2,
  altLabel2,
}) => (
  <Grid
    container
    justify={justify}
    style={{ width: "100%", marginRight: "0", marginLeft: "0" }}
  >
    {altAction ? (
      <Grid item xs={gridSize / 2}>
        <Button fullWidth component={Link} to={altAction}>
          {altLabel}
        </Button>
      </Grid>
    ) : (
      <div />
    )}
    <Grid item xs={gridSize / 2}>
      <div className={classes.submitWrapper}>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {label} {subLabel}
        </Button>
        {loading && <CircularProgress size={24} className={classes.progress} />}
      </div>
    </Grid>
    {altAction2 ? (
      <Grid item xs={gridSize / 2}>
        <Button fullWidth component={Link} to={altAction2}>
          {altLabel2}
        </Button>
      </Grid>
    ) : (
      <div></div>
    )}
  </Grid>
);

/*
 *
 * <Grid container justify={justify}>
        {altAction ? [
            (greyButton
                ? 
                <Grid item xs={gridSize / 2}>
                    <Button fullWidth component={Link} to={altAction} variant="contained" color="grey">
                        {altLabel}
                    </Button>
                </Grid>
             :
                <Grid item xs={gridSize / 2}>
                    <Button fullWidth component={Link} to={altAction} variant="contained" color="primary">
                        {altLabel}
                    </Button>
                </Grid>
            )
        ] : (
            <div />
        )}
        <Grid item xs={gridSize / 2}>
            <div className={classes.submitWrapper}>
                <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
                    {label} {subLabel}
                </Button>
                
            </div>
        </Grid>        
    </Grid>
    */
//
export default withStyles(styles)(Submit);
