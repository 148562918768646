import React from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "../common/TextField";

import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import TableCell from "../common/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TablePaginationActionsWrapped from "../common/Paginator";
import Icon from "@mdi/react";
import { GSP } from "./models";
import { mdiUndo, mdiDownload } from "@mdi/js";

const allGSPs = GSP.selectAll();

const styles = theme => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee"
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16
  },
  table: {
    width: "100%",
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)"
    }
  },
  centerAlign: {
    textAlign: "center"
  },
  checkAll: {
    marginTop: "-13px"
  },
  checkAllLbl: {
    paddingTop: "10px"
  },
  selectExportLbl: {
    marginLeft: "15px",
    bottom: "6px"
  }
});

const columnData = [
  { id: "actions", numeric: false, label: "Include", allowSort: false },
  { id: "name", numeric: false, label: "Name", allowSort: true },
  {
    id: "abbreviation",
    numeric: false,
    label: "Abbreviation",
    allowSort: true
  },
  { id: "id", numeric: false, label: "ID", allowSort: false }
];

class AdminExportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      order: "asc",
      orderBy: null,
      page: 0,
      rowsPerPage: 25,
      dataExport: "wl",
      checkAll: false,
      checked: [],
      checkInit: false
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    order === "desc"
      ? this.props.gsps.sort((a, b) =>
          b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1
        )
      : this.props.gsps.sort((a, b) =>
          a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1
        );

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  handleChangeDataType = event => {
    this.setState({ dataExport: event.target.value });
  };

  handleCheckAll = event => {
    const { gsps, ormGSPUpdateLocalOnly } = this.props;
    const { checked } = this.state;
    var checkState = !this.state.checkAll;
    var newChecked = checked;

    gsps.map(item => {
      ormGSPUpdateLocalOnly({
        ...item,
        checked: checkState
      });

      var index = newChecked.indexOf(item.id);

      if (checkState && index === -1) {
        newChecked.push(item.id);
      } else if (!checkState && index > -1) {
        newChecked.splice(index, 1);
      }
    });

    this.setState({
      checkAll: checkState,
      checked: newChecked
    });
  };

  ItemCheck = id => {
    const { gsps, ormGSPUpdateLocalOnly } = this.props;
    const { checked } = this.state;

    var newChecked = checked;

    gsps
      .filter(g => g.id === id)
      .map(item => {
        var checkState = !item.checked ? true : false;

        ormGSPUpdateLocalOnly({
          ...item,
          checked: checkState
        });

        var index = newChecked.indexOf(item.id);

        if (checkState && index === -1) {
          newChecked.push(item.id);
        } else if (!checkState && index > -1) {
          newChecked.splice(index, 1);
        }
      });

    this.setState({
      checked: newChecked
    });
  };

  initCheckboxes() {
    const { gsps } = this.props;
    const { checked, checkInit } = this.state;

    if (!checkInit && gsps.length > 0) {
      var newChecked = [];

      gsps
        .filter(g => g.checked === true)
        .map(item => {
          newChecked.push(item.id);
        });

      this.setState({
        checked: newChecked,
        checkInit: true
      });
    }
  }

  createReport() {
    var ids = [];
    const { gsps } = this.props;

    gsps
      .filter(g => g.checked === true)
      .map(item => {
        ids.push(item.id);
      });

    console.log(ids);
  }

  render() {
    const { classes, history, gsps } = this.props;
    const {
      order,
      orderBy,
      page,
      rowsPerPage,
      dataExport,
      checked
    } = this.state;

    var filtered_items = gsps;

    this.initCheckboxes();

    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Export Data"
              level2link="/AdminExportData"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/administration")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Administration
            </Button>
          </Grid>
          <Grid item xs={10} sm={6} md={4} lg={3}></Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <EnhancedTableHead
                columnData={columnData}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {filtered_items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          className="checkbox"
                          name={item.id}
                          checked={item.checked ? true : false}
                          onChange={() => this.ItemCheck(item.id)}
                        />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.abbreviation}</TableCell>
                      <TableCell>{item.id}</TableCell>
                    </TableRow>
                  ))}
                {filtered_items.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={4} className={classes.centerAlign}>
                      No GSPs Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {filtered_items.length > 25 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={4}
                      count={filtered_items.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkAll}
                  color="primary"
                  name="checkall"
                  onChange={this.handleCheckAll}
                />
              }
              className={classes.checkAllLbl}
              label="Check All"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Select
                  id="selectExport"
                  value={dataExport}
                  onChange={this.handleChangeDataType}
                  className={classes.selectExportLbl}
                >
                  <MenuItem value={"wl"}>Water Level Data</MenuItem>
                  <MenuItem value={"ws"}>Well Sites Data</MenuItem>
                </Select>
              }
              label="Data Export Target:"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              disabled={checked.length > 0 ? false : true}
              fullWidth
              onClick={() => {
                this.createReport();
              }}
            >
              <Icon path={mdiDownload} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Export Data
            </Button>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }
}

AdminExportData = connect(
  (state, ownProps) => ({
    gsps: allGSPs(state),
    authState: state.auth
  }),
  {
    ...GSP.actions
  }
)(AdminExportData);
export default withStyles(styles)(withRouter(AdminExportData));
