import { fk } from "redux-orm"; // attr, Schema, many
import orm, { Model } from "../common/orm";

export class Well extends Model {
    static get apiUrl() {
        return "/wells/";
    }
    static get source() {
        return "/wells/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "Well";
    }
}
orm.register(Well);

export class WellSite extends Model {
    static get apiUrl() {
        return "/wellsites/";
    }
    static get source() {
        return "/wellsites/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WellSite";
    }
}
orm.register(WellSite);

export class StreamSite extends Model {
    static get apiUrl() {
        return "/streamsites/";
    }
    static get source() {
        return "/streamsites/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "StreamSite";
    }
}
orm.register(StreamSite);

export class SWGWSite extends Model {
    static get apiUrl() {
        return "/swgwsites/";
    }
    static get source() {
        return "/swgwsites/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "SWGWSite";
    }
}
orm.register(SWGWSite);

export class SubsidenceSite extends Model {
    static get apiUrl() {
        return "/subsidencesites/";
    }
    static get source() {
        return "/subsidencesites/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "SubsidenceSite";
    }
}
orm.register(SubsidenceSite);

export class WaterQualitySite extends Model {
    static get apiUrl() {
        return "/wellqualitys/";
    }
    static get source() {
        return "/wellqualitys/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WaterQualitySite";
    }
}
orm.register(WaterQualitySite);

export class WaterQualityMeasurement extends Model {
    static get apiUrl() {
        return "/waterqualitymeasurements/";
    }
    static get source() {
        return "/waterqualitymeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("SubsidenceSite", "waterqualitymeasurements"),
        };
    }
    static get modelName() {
        return "WaterQualityMeasurement";
    }
    static get initialLoad() {
        return false;
    }
}
orm.register(WaterQualityMeasurement);

export class GroundwaterSite extends Model {
    static get apiUrl() {
        return "/groundwatersites/";
    }
    static get source() {
        return "/groundwatersites/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "GroundwaterSite";
    }
}
//orm.register(GroundwaterSite);

export class WellSiteMeasurement extends Model {
    static get apiUrl() {
        return "/wellsitemeasurements/";
    }
    static get source() {
        return "/wellsitemeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("WellSite", "wellsitemeasurements"),
        };
    }
    static get modelName() {
        return "WellSiteMeasurement";
    }
    static get initialLoad() {
        return false;
    }
}
orm.register(WellSiteMeasurement);

export class SWGWMeasurement extends Model {
    static get apiUrl() {
        return "/swgwmeasurements/";
    }
    static get source() {
        return "/swgwmeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("SWGWSite", "wellsitemeasurements"),
        };
    }
    static get modelName() {
        return "SWGWSiteMeasurement";
    }
    static get initialLoad() {
        return false;
    }
}
//orm.register(SWGWMeasurement);

export class SubsidenceMeasurement extends Model {
    static get apiUrl() {
        return "/subsidencemeasurements/";
    }
    static get source() {
        return "/subsidencemeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("SubsidenceSite", "subsidencemeasurements"),
        };
    }
    static get modelName() {
        return "SubsidenceMeasurement";
    }
    static get initialLoad() {
        return false;
    }
}
orm.register(SubsidenceMeasurement);

export class GroundwaterMeasurement extends Model {
    static get apiUrl() {
        return "/groundwatermeasurements/";
    }
    static get source() {
        return "/groundwatermeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("GroundwaterSite", "groundwatermeasurements"),
        };
    }
    static get modelName() {
        return "GroundwaterMeasurement";
    }
}
//orm.register(GroundwaterMeasurement);

export class WaterMeter extends Model {
    static get apiUrl() {
        return "/watermeters/";
    }
    static get source() {
        return "/watermeters/";
    }
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WaterMeter";
    }
}
orm.register(WaterMeter);

export class WaterMeterMeasurement extends Model {
    static get apiUrl() {
        return "/watermetermeasurements/";
    }
    static get source() {
        return "/watermetermeasurements/";
    }
    static get fields() {
        return {
            dms_site_id_id: fk("WaterMeter", "watermetermeasurements"),
        };
    }
    static get modelName() {
        return "WaterMeterMeasurement";
    }
    static get initialLoad() {
        return false;
    }
}
orm.register(WaterMeterMeasurement);
