import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles } from "@material-ui/core";
import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";
import Icon from "@mdi/react";
import {
  mdiAccountEditOutline,
  mdiTableEdit,
  mdiPencil,
  mdiCheckDecagram,
  mdiDownload,
  mdiFileDocument,
  mdiCalendarCheck,
} from "@mdi/js";
import BreadcrumbNav from "../common/BreadCrumb";
import requestConfig from "../../api/fetchConfig";

const styles = (theme) => ({
  listItem: {
    border: "1px solid ",
    borderColor: theme.palette.primary.main,
  },
  listItemNoBottom: {
    borderBottom: 0,
  },
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
});

class AdminNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { appConfig: undefined, isFetching: undefined };
  }
  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  render() {
    const { classes, authState } = this.props;
    const { appConfig, isFetching } = this.state;

    let token = authState && authState.user ? authState.user.auth_token : "";

    //get the config data
    if (token !== "" && appConfig === undefined && !isFetching) {
      this.setState({ ...this.state, isFetching: true });
      requestConfig(token).then((data) => {
        this.setState({ appConfig: data, isFetching: false });
      });
    }

    if (appConfig !== undefined) {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="Administration"
                level1link="/administration"
              />
            </Grid>
            {appConfig.ADMIN.MU.enabled === true ? (
              <Grid item xs={12} lg={7}>
                <ListItem
                  button
                  component={Link}
                  to="/adminusers"
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon path={mdiAccountEditOutline} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage Users" />
                </ListItem>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} lg={7}>
              {appConfig.ADMIN.MA.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminagencies"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiTableEdit} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage Agencies" />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.GSA.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/admingsas"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiTableEdit} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage GSAs" />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.GSP.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/admingsps"
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon path={mdiTableEdit} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage GSPs" />
                </ListItem>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} lg={7}>
              {appConfig.ADMIN.WMS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminwellsites"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiPencil} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage Well Monitoring Sites" />
                </ListItem>
              ) : (
                <></>
              )}

              {appConfig.ADMIN.METERS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminwatermeters"
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon path={mdiPencil} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Manage Water Meter Sites" />
                </ListItem>
              ) : (
                <></>
              )}

              {appConfig.ADMIN.SMS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminsubsidencesites"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiPencil} size={1} />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary="Manage Subsidence Monitoring Sites"
                  />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.IMS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminsurfacewatersites"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiPencil} size={1} />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary="Manage Interconnected SW Monitoring Sites"
                  />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.STMS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminstreamsites"
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon path={mdiPencil} size={1} />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary="Manage Stream Monitoring Sites"
                  />
                </ListItem>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} lg={7}>
              {appConfig.ADMIN.RS.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/gspreportingstatus"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiCheckDecagram} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="View GSP Reporting Status" />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.CR.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/admincreatereport"
                  className={classNames(
                    classes.listItem,
                    classes.listItemNoBottom
                  )}
                >
                  <ListItemIcon>
                    <Icon path={mdiFileDocument} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Create Report" />
                </ListItem>
              ) : (
                <></>
              )}
              {appConfig.ADMIN.WY.enabled === true ? (
                <ListItem
                  button
                  component={Link}
                  to="/adminwateryear"
                  className={classNames(classes.listItem)}
                >
                  <ListItemIcon>
                    <Icon path={mdiCalendarCheck} size={1} />
                  </ListItemIcon>
                  <ListItemText inset primary="Set Water Year" />
                </ListItem>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <NotReady message="Loading..." />
        </AppContainer>
      );
    }
  }
}

AdminNavigation = connect(
  (state) => ({ authState: state.auth }),
  null
)(AdminNavigation);

export default withStyles(styles)(withRouter(AdminNavigation));
